













































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

let id = 0;

@Component
export default class UCheckbox extends Vue {
    @Prop({type: Boolean, default: false})
    private value!: boolean;

    @Prop({type: Boolean, default: false})
    private required!: boolean;

    @Prop({type: Boolean, default: false})
    private disabled!: boolean;

    @Prop({type: String, default: ''})
    private description!: string;

    private valueLocal: boolean = this.value;
    private id = '';

    constructor() {
        super();

        id++;
        this.id = 'ucheckbox_' + id;
    }

    @Watch('value')
    private onValueChange(val: boolean) {
        this.valueLocal = val;
    }

    @Watch('valueLocal')
    private onValueLocaleChange(val: boolean) {
      this.$emit('input', val);
    }

    private get className() {
        return [
            'u-checkbox',

            {
                'u-checkbox__has-label': 'default' in this.$slots,
                'u-checkbox__disabled': this.disabled,
            },
        ];
    }
}
