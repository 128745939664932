var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"track-table-list"},[_c('u-table',{attrs:{"data":_vm.list,"fields":_vm.fields},scopedSlots:_vm._u([{key:"column_select",fn:function(ref){
var row = ref.row;
return [_c('u-checkbox',{attrs:{"value":_vm.selected.includes(row.id)},on:{"input":function($event){return _vm.toggleSelectTrack(row, $event)}}})]}},{key:"column_play-pause",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"track-table-list_play-pause",on:{"click":function($event){return _vm.togglePlay(row)}}},[_c('icon',{attrs:{"name":_vm.isPlaying(row) ? 'pause' : 'play'}})],1)]}},{key:"column_title",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"track-table-list_title"},[_c('div',{staticClass:"track-table-list_title_title"},[_vm._v(_vm._s(row.title))]),_c('div',{staticClass:"track-table-list_title_artist"},[_vm._v(_vm._s(row.artist.name))]),(row.isrc)?_c('div',{staticClass:"track-table-list_title_isrc"},[_vm._v(_vm._s(row.isrc))]):_vm._e()])]}},{key:"column_controls",fn:function(ref){
var row = ref.row;
return [_vm._t("controls",null,{"row":row})]}},{key:"column_compilations",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._l((row.compilations),function(compilation,index){return _c('span',{key:compilation.id},[_c('router-link',{staticClass:"link",attrs:{"to":{name: 'compilationItem', params: {id: compilation.id}}}},[_vm._v(_vm._s(compilation.name))]),_vm._v(_vm._s((row.compilations.length - 1 > index ? ',' : ''))+" ")],1)}),(row.status === 1)?_c('span',{staticClass:"track-table-list_change-compilation",on:{"click":function($event){return _vm.changeCompilations(row)}}},[_c('icon',{attrs:{"name":"pencil"}})],1):_vm._e()],2)]}},{key:"column_playlists",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._l((row.playlists),function(playlist,index){return _c('span',{key:playlist.id},[_c('router-link',{staticClass:"link",attrs:{"to":{name: 'playlistItem', params: {id: playlist.id}}}},[_vm._v(_vm._s(playlist.title))]),_vm._v(_vm._s((row.playlists.length - 1 > index ? ',' : ''))+" ")],1)}),(row.status === 1)?_c('span',{staticClass:"track-table-list_change-compilation",on:{"click":function($event){return _vm.changePlaylists(row)}}},[_c('icon',{attrs:{"name":"pencil"}})],1):_vm._e()],2)]}}],null,true)}),(_vm.list.length === 0)?_c('div',{staticClass:"track-table-list_empty"},[_vm._v(" Нет треков ")]):_c('div',{staticClass:"track-table-list_select"},[_c('div',{staticClass:"track-table-list_select_count"},[_vm._v(" Выбрано: "+_vm._s(_vm.selected.length)+" ")]),_c('u-button',{attrs:{"small":"","theme":"gray-outline"},on:{"click":_vm.selectAll}},[_vm._v(" Выбрать все ")]),(_vm.hasDownload && _vm.selected.length)?_c('u-button',{attrs:{"loading":_vm.getDownloadMP3URLIsLoading,"icon-left":"arrow-down-bold","small":""},on:{"click":_vm.downloadSelected}},[_vm._v(" Скачать ")]):_vm._e(),(_vm.hasSelectNext && _vm.selected.length)?_c('div',{staticClass:"track-table-list_select_next"},[_c('u-button',{attrs:{"small":""},on:{"click":_vm.selectNext}},[_vm._v(" Продолжить ")])],1):_vm._e(),(_vm.hasSelectDelete && _vm.selected.length)?_c('div',{staticClass:"track-table-list_select_delete"},[_c('u-button',{attrs:{"small":"","theme":"danger"},on:{"click":_vm.selectDelete}},[_vm._v(" Удалить ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }