import {api} from '@/api/api';
import {Compilation, CompilationListItem, CompilationTrackCount} from '@/types';

export const CompilationService = {
    list(): Promise<CompilationListItem[]> {
        return api.get('/compilation/list');
    },


    item(compilationId: number): Promise<Compilation> {
        return api.get(`/compilation/${compilationId}`);
    },

    trackCount(): Promise<CompilationTrackCount[]> {
        return api.get('/compilation/track-count');
    },

    trackDetach(compilationId: number, trackId: number): Promise<void> {
        return api.post('/compilation/track-detach', {
            compilationId,
            trackId,
        });
    }
};
