import {api} from '@/api/api';
import {Playlist, PlaylistForm, TrackListItem} from '@/types';
import {ListResponse, OrderDir} from "@/api/index";
import {Route} from "vue-router";

export enum PlaylistOrderColumn {
  id = 'id',
  totalTracks = 'totalTracks',
  totalDuration = 'totalDuration',
}

export interface PlaylistListParams {
  limit: number;
  start: number;
  genreIDs?: number[];
  orderColumn?: PlaylistOrderColumn;
  orderDir?: OrderDir;
}

export const PlaylistService = {
  prepareListParams(r: Route, defaultLimit: number): PlaylistListParams {
    const res: PlaylistListParams = {
      limit: defaultLimit,
      start: 0,
    };

    if (r.query.limit) {
      res.limit = parseInt(r.query.limit.toString(), 10);
    }

    if (r.query.start) {
      res.start = parseInt(r.query.start.toString(), 10);
    }

    if (r.query.genreIDs) {
      res.genreIDs = r.query.genreIDs
              .toString()
              .split(',')
              .map((v) => parseInt(v, 10));
    }

    if (r.query.sortField) {
      switch (r.query.sortField.toString()) {
        case PlaylistOrderColumn.id:
          res.orderColumn = PlaylistOrderColumn.id;
          break;
        case PlaylistOrderColumn.totalTracks:
          res.orderColumn = PlaylistOrderColumn.totalTracks;
          break;
        case PlaylistOrderColumn.totalDuration:
          res.orderColumn = PlaylistOrderColumn.totalDuration;
          break;
      }
    }

    if (r.query.sortOrder) {
      switch (r.query.sortOrder.toString()) {
        case OrderDir.asc:
          res.orderDir = OrderDir.asc;
          break;
        case OrderDir.desc:
          res.orderDir = OrderDir.desc;
          break;
      }
    }

    return res;
  },

  list(params: PlaylistListParams): Promise<ListResponse<Playlist>> {
    // eslint-disable-next-line
    const query: Record<string, any> = {...params};

    if (params.genreIDs) {
      query['genreIDs'] = params.genreIDs.join(',');
      delete query.genreIDs;
    }

    return api.get('/playlist', query);
  },

  item(id: number): Promise<Playlist> {
    return api.get(`/playlist/${id}`);
  },

  upsert(form: PlaylistForm): Promise<Playlist> {
    const formData = new FormData();

    if (form.id) {
      formData.append('id', form.id.toString());
    }

    form.musicPresetIDs.forEach((presetID) => {
      formData.append('musicPresetIDs[]', presetID.toString());
    })

    formData.append('title', form.title);
    formData.append('description', form.description);

    if (form.image) {
      formData.append('image', form.image);
    }

    if (form.dynamics) {
      formData.append('dynamics', form.dynamics.toString());
    }

    if (form.acoustics) {
      formData.append('acoustics', form.acoustics.toString());
    }

    return api.post('/playlist', formData);
  },

  attach(id: number, trackIDs: number[]): Promise<Playlist> {
    return api.post(`/playlist/${id}/attach`, {
      trackIDs,
    });
  },

  detach(id: number, trackIDs: number[]): Promise<Playlist> {
    return api.post(`/playlist/${id}/detach`, {
      trackIDs,
    });
  },

  trackSync(trackID: number, playlistIDs: number[]): Promise<TrackListItem> {
    return api.post(`/playlist/track-sync/${trackID}`, {
      playlistIDs,
    });
  },
};
