













import {Component, Vue} from 'vue-property-decorator';
import TrackChangeCompilations from './TrackChangeCompilations.vue';
import PlaylistFormModal from './PlaylistFormModal.vue';
import TracksSelectModal from './TracksSelectModal.vue';
import CompilationSelectModal from './CompilationSelectModal.vue';
import PlaylistsSelectModal from './PlaylistsSelectModal.vue';

@Component({
  components: {
    TrackChangeCompilations,
    PlaylistFormModal,
    TracksSelectModal,
    CompilationSelectModal,
    PlaylistsSelectModal,
  },
})
export default class extends Vue {
}
